import React from 'react';


const TrustSection = () => (
  <div data-name="section" data-section="trustSection" className="trust-section">
    <div id="trustSection-MountListener" className="mount-listener"></div>
    <div className="trust-content">
      <div className="item">
        <div className="icon">
          <span className="icon-bis"></span>
        </div>
        <div className="text">
          <p className="main-text">100% IGI</p>
          <p className="sub-text">Hallmarked Jewellery</p>
        </div>
      </div>
      {/* <div className="item">
        <div className="icon">
          <span className="icon-trust"></span>
        </div>
        <div className="text">
          <p className="main-text">Trust of Fine1Jewels</p>
          <p className="sub-text">Fine1Jewels Privileges</p>
        </div>
      </div> */}
      <div className="item">
        <div className="icon">
          <span className="icon-certified"></span>
        </div>
        <div className="text">
          <p className="main-text">100% Certified</p>
          <p className="sub-text">by Fine1Jewels</p>
        </div>
      </div>
    </div>
  </div>
);

export default TrustSection;
