import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../store/reducers/cartReducer";
import { useUserRegisterMutation } from "../../store/services/authService";
import toast from "react-hot-toast";
import { setUserToken } from "../../store/reducers/authReducer";
import LoginModal from "../LoginModal/LoginModal";

const Header = () => {
  const dispatch = useDispatch();
  const { data, isFetching } = useAllCategoriesQuery();
  const { cart, total, totalshipping ,wishlist} = useSelector((state) => state.cart);
  const { userToken, user } = useSelector((state) => state.authReducer);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleLoginClick = (e) => {
    if (!userToken) {
      e.preventDefault(); // Prevents the default link behavior
      setShowModal(true); // Show the modal
    }
  };
  return (
    <header
      id="site-header"
      //   className="site-header header-v1 color-white bg-transparent absolute top-0 left-0 right-0 z-50"
      className="site-header header-v1 color-white bg-black"
    >
      <LoginModal showModal={showModal} setShowModal={setShowModal} />
      <div className="header-mobile">
        <div className="section-padding">
          <div className="section-container">
            <div className="row align-items-center justify-between">
              {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-left"> */}
                {/* <div className="navbar-header">
                  <button
                    type="button"
                    id="show-megamenu"
                    className="navbar-toggle"
                    aria-label="Toggle Menu"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div> */}
              {/* </div> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 header-center">
                <div className="site-logo">
                  <Link to="/">
                    <img
                    
                    style={{width:"65px",height:'150px'}}
                    // className="object-contain"
                    src="/media/logo-white.png"
                    alt="Fine1Jewels – Jewelry Store"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-right">
                <div className="mojuri-topcart dropdown">
                  <div className="dropdown mini-cart top-cart">
                    <div className="remove-cart-shadow" />
                    <Link
                      className="dropdown-toggle cart-icon"
                      to="/Cart"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="icons-cart">
                        <i className="icon-large-paper-bag" />
                        <span className="cart-count">{cart.length}</span>

                      </div>
                    </Link>
                    <div className="dropdown-menu cart-popup">
                      <div className="cart-empty-wrap">
                        <ul className="cart-list">
                          <li className="empty">
                            <span>No products in the cart.</span>
                            <Link className="go-shop" to="/">
                              GO TO SHOP
                              <i aria-hidden="true" className="arrow_right" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="cart-list-wrap">
                        <ul className="cart-list ">
                          <li className="mini-cart-item">
                            <Link
                              to="/Shop"
                              className="remove"
                              title="Remove this item"
                            >
                              <i className="icon_close" />
                            </Link>
                            <Link
                              to="/ProductDetails"
                              className="product-image"
                            >
                              <img
                                width={600}
                                height={600}
                                src="media/product/3.jpg"
                                alt="Twin Hoops"
                              />
                            </Link>
                            <Link to="/ProductDetails" className="product-name">
                              Twin Hoops
                            </Link>
                            <div className="quantity">Qty: 1</div>
                            <div className="price">$150.00</div>
                          </li>
                          <li className="mini-cart-item">
                            <Link
                              to="/Shop"
                              className="remove"
                              title="Remove this item"
                            >
                              <i className="icon_close" />
                            </Link>
                            <Link
                              to="/ProductDetails"
                              className="product-image"
                            >
                              <img
                                width={600}
                                height={600}
                                src="media/product/1.jpg"
                                alt="Medium Flat Hoops"
                              />
                            </Link>
                            <Link to="/ProductDetails" className="product-name">
                              Medium Flat Hoops
                            </Link>
                            <div className="quantity">Qty: 1</div>
                            <div className="price">$100.00</div>
                          </li>
                        </ul>
                        <div className="total-cart">
                          <div className="title-total">Total: </div>
                          <div className="total-price">
                            <span>$250.00</span>
                          </div>
                        </div>
                        <div className="free-ship">
                          <div className="title-ship">
                            Buy <strong>$400</strong> more to enjoy{" "}
                            <strong>FREE Shipping</strong>
                          </div>
                          <div className="total-percent">
                            <div className="percent" style={{ width: "20%" }} />
                          </div>
                        </div>
                        <div className="buttons">
                          <Link
                            to="/Cart"
                            className="button btn view-cart btn-primary"
                          >
                            View cart
                          </Link>
                          <Link
                            onClick={() =>{
                              if (userToken) {
    
                                console.log("ok ")
                                navigate("/Checkout");
                              } else {
                                setShowModal(true)
                              }
                             
                            }}
                            className="button btn checkout btn-default"
                          >
                            Check out
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-mobile-fixed">
          {/* Shop */}
          <div className="shop-page">
            <Link to="/">
              <i className="wpb-icon-shop" />
            </Link>
          </div>
          {/* Login */}
          <div className="my-account">
            <div className="login-header">
              {userToken ? (
                <Link to="/MyAccount" className="active-login">
                  <i className="icon-user" />
                </Link>
              ) : (
                <button className="active-login" onClick={handleLoginClick}>
                  <i className="icon-user" />
                </button>
              )}
            </div>
          </div>
          {/* Search */}
          <div className="search-box">
            <div className="search-toggle">
              <i className="wpb-icon-magnifying-glass" />
            </div>
          </div>
          {/* Wishlist */}
          <div className="wishlist-box">
            <Link to="/Wishlist">
              <i className="wpb-icon-heart" />
            </Link>
          </div>
        </div>
      </div>
      <div className="header-desktop">
        <div className="header-wrapper">
          <div className="section-padding">
            <div className="section-container large p-l-r">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 header-left">
                  <div className="site-logo">
                    <Link to="/">
                      <img
                      style={{width:"80px",height:'150px'}}
                      
                      src="/media/logo-white.png"
                      alt="Fine1Jewels – Jewelry Store"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center header-center">
                  <div className="site-navigation">
                    <nav id="main-navigation">
                      <ul id="menu-main-menu" className="menu">
                        <li className="level-0 menu-item menu-item-has-children current-menu-item">
                          <Link to="/">
                            <span className="menu-item-text">Home</span>
                          </Link>
                        </li>
                        <li className="level-0 menu-item menu-item-has-children">
                          <Link to="/">
                            <span className="menu-item-text">Shop</span>
                          </Link>
                          <ul className="sub-menu">
                            {data?.categories.map((category, index) => {
                              return (
                                <li>
                                  <Link
                                    to={`/Shop/${category.name}/${category._id}`}
                                  >
                                    <span className="menu-item-text">
                                      {category.name}
                                    </span>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                        <li className="level-0 menu-item menu-item-has-children mega-menu mega-menu-fullwidth align-center">
                          <Link to="/Blog">
                            <span className="menu-item-text">Blog</span>
                          </Link>
                          <div className="sub-menu">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="menu-section">
                                  <h2 className="sub-menu-title">
                                    Blog Category
                                  </h2>
                                  <ul className="menu-list">
                                    <li>
                                      <Link to="/Blog">
                                        <span className="menu-item-text">
                                          Category 1
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/Blog">
                                        <span className="menu-item-text">
                                          Category 2
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/Blog">
                                        <span className="menu-item-text">
                                          Category 3
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/Blog">
                                        <span className="menu-item-text">
                                          Category 4
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/Blog">
                                        <span className="menu-item-text">
                                          Category 5
                                        </span>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                {/* <div className="menu-section">
                                  <h2 className="sub-menu-title">
                                    Blog Details
                                  </h2>
                                  <ul className="menu-list">
                                    <li>
                                      <Link to="/BlogDetails">
                                        <span className="menu-item-text">
                                          Details 1
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/BlogDetails">
                                        <span className="menu-item-text">
                                          Details 2
                                        </span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/BlogDetails">
                                        <span className="menu-item-text">
                                          Details 3
                                        </span>
                                      </Link>
                                    </li>
                                  </ul>
                                </div> */}
                              </div>
                              <div className="col-md-7">
                                <div className="menu-section">
                                  <h2 className="sub-menu-title">
                                    Recent Posts
                                  </h2>
                                  <div className="block block-posts recent-posts p-t-5">
                                    <ul className="posts-list">
                                      <li className="post-item">
                                        <Link
                                          to="/BlogDetails"
                                          className="post-image"
                                        >
                                          <img
                                            src="/media/blog/1.jpg"
                                            alt="Bridal Fair Collections 2023"
                                          />
                                        </Link>
                                        <div className="post-content">
                                          <h2 className="post-title">
                                            <Link to="/BlogDetails">
                                              Bridal Fair Collections 2023
                                            </Link>
                                          </h2>
                                          <div className="post-time">
                                            <span className="post-date">
                                              May 30, 2022
                                            </span>
                                            <span className="post-comment">
                                              4 Comments
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="post-item">
                                        <Link
                                          to="/BlogDetails"
                                          className="post-image"
                                        >
                                          <img
                                            src="/media/blog/2.jpg"
                                            alt="Our Sterling Silver"
                                          />
                                        </Link>
                                        <div className="post-content">
                                          <h2 className="post-title">
                                            <Link to="/BlogDetails">
                                              Our Sterling Silver
                                            </Link>
                                          </h2>
                                          <div className="post-time">
                                            <span className="post-date">
                                              Aug 24, 2022
                                            </span>
                                            <span className="post-comment">
                                              2 Comments
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="post-item">
                                        <Link
                                          to="/BlogDetails"
                                          className="post-image"
                                        >
                                          <img
                                            src="media/blog/3.jpg"
                                            alt="Kitchen Inspired On Japanese"
                                          />
                                        </Link>
                                        <div className="post-content">
                                          <h2 className="post-title">
                                            <Link to="/BlogDetails">
                                              Kitchen Inspired On Japanese
                                            </Link>
                                          </h2>
                                          <div className="post-time">
                                            <span className="post-date">
                                              Dec 06, 2022
                                            </span>
                                            <span className="post-comment">
                                              1 Comment
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="level-0 menu-item menu-item-has-children">
                          <Link to="/AboutUs">
                            <span className="menu-item-text">About Us</span>
                          </Link>
                        </li>
                        <li className="level-0 menu-item">
                          <Link to="/Contact">
                            <span className="menu-item-text">Contact</span>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 header-right">
                  <div className="header-page-link">
                    {/* Search */}
                    <div className="search-box">
                      <div className="search-toggle">
                        <i className="icon-search" />
                      </div>
                    </div>
                    {/* Login */}
                    <div className="login-header icon">
                      {userToken ? (
                        <Link to="/MyAccount" className="active-login">
                          <i className="icon-user" />
                        </Link>
                      ) : (
                        <button
                          className="active-login"
                          onClick={handleLoginClick}
                        >
                          <i className="icon-user" />
                        </button>
                      )}
                    </div>
                    {/* Wishlist */}
                    <div className="wishlist-box">
                      <Link to="/Wishlist">
                        <i className="icon-heart" />
                      </Link>
                      <span className="count-wishlist">{wishlist.length}</span>
                    </div>
                    {/* Cart */}
                    <div className="mojuri-topcart dropdown light">
                      <div className="dropdown mini-cart top-cart">
                        <div className="remove-cart-shadow" />
                        <a
                          className="dropdown-toggle cart-icon"
                          href="/cart"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="icons-cart">
                            <i className="icon-large-paper-bag" />
                            <span className="cart-count">{cart.length}</span>
                          </div>
                        </a>
                        <div className="dropdown-menu cart-popup">
                          {!cart.length > 0 ? (
                            <div className="cart-empty-wrap">
                              <ul className="cart-list">
                                <li className="empty">
                                  <span>No products in the cart.</span>
                                  <Link to="/" className="go-shop">
                                    GO TO SHOP
                                    <i
                                      aria-hidden="true"
                                      className="arrow_right"
                                    />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div className="cart-list-wrap">
                              <ul className="cart-list ">
                                {cart.map((item) => (
                                  <li className="mini-cart-item">
                                    <a
                                      href="#"
                                      className="remove"
                                      title="Remove this item"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you sure you want to delete this item?"
                                          )
                                        ) {
                                          dispatch(removeItem(item._id));
                                        }
                                      }}
                                    >
                                      <i className="icon_close" />
                                    </a>
                                    <Link
                                      to="/ProductDetails"
                                      className="product-image"
                                    >
                                      <img
                                        width={600}
                                        height={600}
                                        src={`https://fine1jewels.com/images/${item.image}`}
                                        alt=""
                                      />
                                    </Link>
                                    <Link
                                      to="/ProductDetails"
                                      className="product-name"
                                    >
                                      {item.title}
                                    </Link>
                                    <div className="quantity">
                                      Qty: {item.quantity}
                                    </div>
                                    {/* <div className="price">$150.00</div> */}
                                  </li>
                                ))}
                              </ul>
                              <div className="total-cart">
                                <div className="title-total">Total Items: </div>
                                <div className="total-price">
                                  <span>{cart.length}</span>
                                </div>
                              </div>
                              {/* <div className="free-ship">
                              <div className="title-ship">
                                Buy <strong>$400</strong> more to enjoy{" "}
                                <strong>FREE Shipping</strong>
                              </div>
                              <div className="total-percent">
                                <div
                                  className="percent"
                                  style={{ width: "20%" }}
                                />
                              </div>
                            </div> */}
                              <div className="buttons">
                                <Link
                                  to="/Cart"
                                  className="button btn view-cart btn-primary"
                                >
                                  View cart
                                </Link>
                                <Link
                                   onClick={() =>{
                                    if (userToken) {
          
                                      console.log("ok ")
                                      navigate("/Checkout");
                                    } else {
                                      setShowModal(true)
                                    }
                                   
                                  }}
                                  className="button btn checkout btn-default"
                                >
                                  Check out
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
