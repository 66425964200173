import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Image from "../designLayouts/Image";
import SamplePrevArrow from "../Arrows/PreviousArrow";
import SampleNextArrow from "../Arrows/NextArrow";
import { useGetAllActiveBannerImagesForUserQuery } from "../../store/services/bannerImageService";
import "swiper/css";

const Banner = () => {
  const { data, isFetching } = useGetAllActiveBannerImagesForUserQuery();
  const [dotActive, setDotActive] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    beforeChange: (prev, next) => setDotActive(next),
    // appendDots: (dots) => (
    //   <div
    //     style={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "7%",
    //       transform: "translateY(-50%)",
    //     }}
    //   >
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
    // customPaging: (i) => (
    //   <div
    //     style={
    //       i === dotActive
    //         ? {
    //             width: "30px",
    //             color: "#262626",
    //             borderRight: "3px #262626 solid",
    //             padding: "8px 0",
    //             cursor: "pointer",
    //           }
    //         : {
    //             width: "30px",
    //             color: "transparent",
    //             borderRight: "3px white solid",
    //             padding: "8px 0",
    //             cursor: "pointer",
    //           }
    //     }
    //   >
    //     0{i + 1}
    //   </div>
    // ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: (dots) => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "2%",
                transform: "translateY(-50%)",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
          customPaging: (i) => (
            <div
              style={
                i === dotActive
                  ? {
                      width: "25px",
                      color: "#262626",
                      borderRight: "3px #262626 solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
                  : {
                      width: "25px",
                      color: "transparent",
                      borderRight: "3px white solid",
                      cursor: "pointer",
                      fontSize: "12px",
                    }
              }
            >
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  return (
    <div className="block block-sliders color-white nav-center">
      <Slider {...settings}>
        {!isFetching &&
          data?.map((item, index) => (
            <div key={index} className="item slick-slide">
              <Link to={item?.url}>
                <div className="item-content">
                  <div className="content-image">
                    <img
                      width={1920}
                      height={1080}
                      src={`https://fine1jewels.com/${item?.image}`}
                      alt="Image Slider"
                    />
                  </div>
                  <div className="item-info horizontal-start vertical-middle">
                    <div className="content">
                      <h2 className="title-slider">
                        Oh,
                        <br /> Hello Newness!
                      </h2>
                      <Link
                        to="/Shop"
                        className="button-slider button button-white button-outline thick-border"
                      >
                        Explore Bestseller
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default Banner;
