import React, { act, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCatProductsQuery, useTrendingProductsQuery } from "../../store/services/homeProducts";
import currency  from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import Loader from "../../components/Loader/Loader";
import HTMLReactParser from "html-react-parser";

const Shop = () => {
  const { name, id } = useParams();
  const [activeLayout, setActiveLayout] = useState('grid');
  const { data:trendingproduct,  isFetching:trendingloading } = useTrendingProductsQuery({ page: 1 });
  const handleLayoutChange = (layout) => {
    setActiveLayout(layout);
    
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const { data, isFetching } = useCatProductsQuery({
    name:id,
    page: currentPage,
  });

  const { data:catdata } = useAllCategoriesQuery();
  return (
    <>
      <div id="page" className="hfeed page-wrapper">
       <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="title" className="page-title">
                <div className="section-container">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">{name}</h1>
                  </div>
                  <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="delimiter" />
                    <Link to="/">Shop</Link>
                    <span className="delimiter" />
                    {name}
                  </div>
                </div>
              </div>
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <div className="section-container p-l-r">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-12 col-12 sidebar left-sidebar md-b-50 p-t-10">
                        {/* Block Product Categories */}
                        <div className="block block-product-cats">
                          <div className="block-title">
                            <h2>Categories</h2>
                          </div>
                          <div className="block-content">
                            <div className="product-cats-list">
                              <ul>
                              {catdata?.categories.map((category, index) => {
return(<li>
                             <li className={category.name == name ? "current" :""}>
                                  <Link to={`/Shop/${category.name}/${category._id}`}>
                                  {category.name} <span className="count">{category.name == name ? data?.count:'-'}</span>
                                  </Link>
                                </li>
                            </li>)})}
                               
                             
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* Block Product Filter */}
                        {/* <div className="block block-product-filter">
                          <div className="block-title">
                            <h2>Price</h2>
                          </div>
                          <div className="block-content">
                            <div
                              id="slider-range"
                              className="price-filter-wrap"
                            >
                              <div className="filter-item price-filter">
                                <div className="layout-slider">
                                  <input
                                    id="price-filter"
                                    name="price"
                                    defaultValue="0;100"
                                  />
                                </div>
                                <div className="layout-slider-settings" />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* Block Product Filter */}
                        {/* <div className="block block-product-filter clearfix">
                          <div className="block-title">
                            <h2>Color</h2>
                          </div>
                          <div className="block-content">
                            <ul className="filter-items color">
                              <li>
                                <Link to="/Shop">
                                  <span className="color-wrap">
                                    <span className="color antique" />
                                    Antique
                                  </span>
                                  <span className="count">3</span>
                                </Link>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span className="color-wrap">
                                    <span className="color bone" />
                                    Bone
                                  </span>
                                  <span className="count">2</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span className="color-wrap">
                                    <span className="color chestnut" />
                                    Chestnut
                                  </span>
                                  <span className="count">5</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span className="color-wrap">
                                    <span className="color crimson" />
                                    Crimson
                                  </span>
                                  <span className="count">8</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span className="color-wrap">
                                    <span className="color eggshell" />
                                    Eggshell
                                  </span>
                                  <span className="count">3</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span className="color-wrap">
                                    <span className="color grullo" />
                                    Grullo
                                  </span>
                                  <span className="count">4</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        {/* Block Product Filter */}
                        {/* <div className="block block-product-filter clearfix">
                          <div className="block-title">
                            <h2>Size</h2>
                          </div>
                          <div className="block-content">
                            <ul className="filter-items text">
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>L</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>M</span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>S</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        {/* Block Product Filter */}
                        {/* <div className="block block-product-filter clearfix">
                          <div className="block-title">
                            <h2>Brands</h2>
                          </div>
                          <div className="block-content">
                            <ul className="filter-items image">
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>
                                    <img src="media/brand/1.jpg" alt="Brand" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>
                                    <img src="media/brand/2.jpg" alt="Brand" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>
                                    <img src="media/brand/3.jpg" alt="Brand" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>
                                    <img src="media/brand/4.jpg" alt="Brand" />
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="shop-grid-left.html">
                                  <span>
                                    <img src="media/brand/5.jpg" alt="Brand" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                        {/* Block Products */}
                        <div className="block block-products">
                          <div className="block-title">
                            <h2>Feature Product</h2>
                          </div>
                          <div className="block-content">
                            <ul className="products-list">

                              {trendingproduct?.products.map((product, index) => (
                              <li className="product-item">
                                <a
                                  href={`/product/${product._id}`}
                                  className="product-image"
                                >
                                  <img  src={`https://fine1jewels.com/images/${product.images[0]}`} />
                                </a>
                                <div className="product-content">
                                  <h2 className="product-title">
                                    <a href={`/product/${product._id}`}>
                                      {product.name}
                                    </a>
                                  </h2>
                                  <div className="rating">
                                    <div className="star star-5" />
                                  </div>
                                  <span className="price">
                                    

                                    {product.discount ? (
                    <>
                      <del aria-hidden="true">
                        <span>{currency.format(product.pricing.finalPrice +  product.discount, { code: "INR" })}</span>
                      </del>
                      <ins>
                        <span>{currency.format(product.pricing.finalPrice, { code: "INR" })}</span>
                      </ins>
                    </>
                  ) : (
                    <span>
                        {currency.format(product.pricing.finalPrice, { code: "INR" })}
                        </span>
                  )}

                                  </span>
                                </div>
                              </li>))}
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-12 col-12">
                        <div className="products-topbar clearfix">
                          <div className="products-topbar-left">
                            <div className="products-count">
                              Showing all {data?.products.length} results
                            </div>
                          </div>
                          <div className="products-topbar-right">
                            {/* <div className="products-sort dropdown">
                              <span
                                className="sort-toggle dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="true"
                              >
                                Default sorting
                              </span>
                              <ul
                                className="sort-list dropdown-menu"
                                x-placement="bottom-start"
                              >
                                <li className="active">
                                  <a href="#">Default sorting</a>
                                </li>
                                <li>
                                  <a href="#">Sort by popularity</a>
                                </li>
                                <li>
                                  <a href="#">Sort by average rating</a>
                                </li>
                                <li>
                                  <a href="#">Sort by latest</a>
                                </li>
                                <li>
                                  <a href="#">Sort by price: low to high</a>
                                </li>
                                <li>
                                  <a href="#">Sort by price: high to low</a>
                                </li>
                              </ul>
                            </div> */}
                            <ul className="layout-toggle nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className={`layout-grid nav-link ${activeLayout === 'grid' ? 'active' : ''}`}
                                  onClick={() => handleLayoutChange('grid')}
                                  data-toggle="tab"
                                  role="tab"
                                >
                                  <span className="icon-column">
                                    <span className="layer first">
                                      <span />
                                      <span />
                                      <span />
                                    </span>
                                    <span className="layer middle">
                                      <span />
                                      <span />
                                      <span />
                                    </span>
                                    <span className="layer last">
                                      <span />
                                      <span />
                                      <span />
                                    </span>
                                  </span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={`layout-list nav-link ${activeLayout === 'list' ? 'active' : ''}`}
                                  onClick={() => handleLayoutChange('list')}
                                  data-toggle="tab"
                                  role="tab"
                                >
                                  <span className="icon-column">
                                    <span className="layer first">
                                      <span />
                                      <span />
                                    </span>
                                    <span className="layer middle">
                                      <span />
                                      <span />
                                    </span>
                                    <span className="layer last">
                                      <span />
                                      <span />
                                    </span>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="tab-content">
                        {activeLayout === 'grid' && (
                          <div
                            className="tab-pane fade show active"
                            id="layout-grid"
                            role="tabpanel"
                          >
                            <div className="products-list grid">
                              <div className="row">
                              {data?.products.map((product, index) => {
return(
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                  <div className="products-entry clearfix product-wapper">
                                    <div className="products-thumb">
                                    {product?.trendingProduct ?<div className="product-lable">
                                        <div className="hot">Hot</div>
                                      </div>:null}
                                      {/* {product?.discount ?
                                      <div className="product-lable">
                                        <div className="onsale">-{product?.discount}</div>
                                      </div>:null} */}
                                      <div className="product-thumb-hover">
                                        <a href={`/product/${product._id}`}>
                                          <img
                                            width={600}
                                            height={600}
                                            src={`https://fine1jewels.com/images/${product?.images[0]}`}
                                            className="post-image"
                                            alt=""
                                          />
                                          <img
                                            width={600}
                                            height={600}
                                            src={`https://fine1jewels.com/images/${product?.images[1]}`}
                                            className="hover-image back"
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <div className="product-button">
                                        <div
                                          className="btn-add-to-cart"
                                          data-title="Add to cart"
                                        >
                                          <a
                                            rel="nofollow"
                                            href="/cart"
                                            className="product-btn button"
                                          >
                                            Add to cart
                                          </a>
                                        </div>
                                        <div
                                          className="btn-wishlist"
                                          data-title="Wishlist"
                                        >
                                          <button className="product-btn">
                                            Add to wishlist
                                          </button>
                                        </div>
                                        <div
                                          className="btn-compare"
                                          data-title="Compare"
                                        >
                                          <button className="product-btn">
                                            Compare
                                          </button>
                                        </div>
                                        <span
                                          className="product-quickview"
                                          data-title="Quick View"
                                        >
                                          <a
                                            href="#"
                                            className="quickview quickview-button"
                                          >
                                            Quick View{" "}
                                            <i className="icon-search" />
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="products-content">
                                      <div className="contents text-center">
                                       
                                        {/* <h3 className="product-title">
                                          <a href={`/product/${product._id}`}>
                                          {product.name}
                                          </a>
                                        </h3> */}
                                        <span className="price">{currency.format(product.pricing.finalPrice, { code: "INR" })}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
)})}
                              
                               
                              </div>
                            </div>
                          </div>
                        )}
                        {activeLayout === 'list' && (
                          <div
                            className="tab-pane fade show active"
                            id="layout-list"
                            role="tabpanel"
                          >
                            <div className="products-list list">
                            {data?.products.map((product, index) => {
return(
                             
                              <div className="products-entry clearfix product-wapper">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="products-thumb">
                                      <div className="product-lable">
                                        <div className="hot">Hot</div>
                                      </div>
                                      <div className="product-thumb-hover">
                                        <a href={`/product/${product._id}`}>
                                          <img
                                            width={600}
                                            height={600}
                                            src={`https://fine1jewels.com/images/${product.images[0]}`}
                                            className="post-image"
                                            alt=""
                                          />
                                          <img
                                            width={600}
                                            height={600}
                                            src={`https://fine1jewels.com/images/${product.images[1]}`}
                                            className="hover-image back"
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                      <span
                                        className="product-quickview"
                                        data-title="Quick View"
                                      >
                                        <a
                                          href="#"
                                          className="quickview quickview-button"
                                        >
                                          Quick View{" "}
                                          <i className="icon-search" />
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="products-content">
                                      <h3 className="product-title">
                                        <a href={`/product/${product._id}`}>
                                          
                                          {currency.format(product.pricing.finalPrice, { code: "INR" })}
                                        </a>
                                      </h3>
                                      <span className="price">
                                      {product.name}
                                      </span>
                                      
                                      <div className="product-button">
                                        <div
                                          className="btn-add-to-cart"
                                          data-title="Add to cart"
                                        >
                                          <a
                                            rel="nofollow"
                                            href={`/product/${product._id}`}
                                            className="product-btn button"
                                          >
                                            Add to cart
                                          </a>
                                        </div>
                                        <div
                                          className="btn-wishlist"
                                          data-title="Wishlist"
                                        >
                                          <button className="product-btn">
                                            Add to wishlist
                                          </button>
                                        </div>
                                        <div
                                          className="btn-compare"
                                          data-title="Compare"
                                        >
                                          <button className="product-btn">
                                            Compare
                                          </button>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
)})}


                         
                            </div>
                          </div>
                        )}
                        </div>
                        <nav className="pagination">
                          <ul className="page-numbers">
                            <li>
                              <a className="prev page-numbers" href="#" onClick={() => handlePageChange(currentPage - 1)}>
                                Previous
                              </a>
                            </li>
                            {data && data.count && data.perPage && [...Array(Math.ceil(data.count / data.perPage)).keys()].map((page) => (
                              <li key={page}>
                                <a className={`page-numbers ${currentPage === page + 1 ? 'current' : ''}`} href="#" onClick={() => handlePageChange(page + 1)}>
                                  {page + 1}
                                </a>
                              </li>
                            ))}
                            <li>
                              <a className="next page-numbers" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                                Next
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>
      {/* Back Top button */}
      <div className="back-top button-show">
        <i className="arrow_carrot-up" />
      </div>
  <Loader isFetching={isFetching}/>
      {/* Page Loader */}
      {/* <div className="page-preloader">
              <div className="loader">
                  <div />
                  <div />
              </div>
          </div> */}
      {/* Dependency Scripts */}
      {/* Site Scripts */}
    </>
  );
};

export default Shop;
